<template>

  <div>

    <machine-edit-token-add-new
      :is-add-new-machine-token-sidebar-active.sync="isAddNewMachineTokenSidebarActive"
      :machine-data="machineData"
      company-options="companyOptions"
      type-options="typeOptions"
      @refetch-machine="refetchMachine"
    />

    <b-tabs
      pills
    >

      <b-tab active>
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('machines.machine') }}</span>
        </template>
        <b-card>
          <!-- Media -->
          <b-media class="mb-2">
            <h4 class="mb-1">
              {{ machineData.name }}
            </h4>
          </b-media>

          <b-row>
            <b-col
              cols="12"
              md="8"
            >

              <!-- Machine Info: Input Fields -->
              <b-form
                class="mb-2"
                @submit_.prevent="handleSubmit(onSubmit)"
              >
                <b-row>

                  <!-- Field: Machinename -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('machines.machine_name')"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="machineData.name"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: serial -- >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Serial"
                      label-for="serial"
                    >
                      <b-form-input
                        id="serial"
                        v-model="machineData.serial"
                      />
                    </b-form-group>
                  </b-col>

                  < !-- -->

                  <!-- Field: Email -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('machines.company')"
                      label-for="machine-company"
                    >
                      <v-select
                        v-model="machineData.companyId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="companyOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="machine-company"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Role -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('machines.location')"
                      label-for="machine-role"
                    >
                      <v-select
                        v-model="machineData.objectId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="objectOptions.filter(item => !machineData.companyId || item.company === machineData.companyId)"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="machine-role"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Full Name -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('machines.mac')"
                      label-for="mac"
                    >
                      <b-form-input
                        id="mac"
                        v-model="machineData.mac"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Type -- >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Type"
                      label-for="machine-type"
                    >
                      <v-select
                        v-model="machineData.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="machine-type"
                      />
                    </b-form-group>
                  </b-col>
                  < !-- -->

                  <!-- Field: Version -- >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="S/W Version"
                      label-for="version"
                    >
                      <b-form-input
                        id="version"
                        v-model="machineData.version"
                      />
                    </b-form-group>
                  </b-col>
                  < !-- -->

                  <!-- Field: Status -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('machines.status')"
                      label-for="machine-status"
                    >
                      <v-select
                        v-model="machineData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="machine-status"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Type -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('machines.type')"
                      label-for="machine-type"
                    >
                      <v-select
                        v-model="machineData.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="machine-type"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
                type_="submit"
                @click="onSubmit"
              >
                {{ $t('common.save_changes') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('common.reset') }}
              </b-button>

            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-card
                _no-body
                class="border mt-1"
              >
                <!--b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="LockIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Settings</span>
                  </b-card-title>
                </b-card-header-->
                <b-row>
                  <template v-for="(ATTR, index) in attrOptionsATTR">
                    <b-col
                      v-if="ATTR.group === 'Machine'"
                      :key="ATTR.name"
                      cols="12"
                      md="12"
                    >
                      <b-form-group v-if="ATTR.owner === 'client'">
                        <b-row><b-col
                          cols="6"
                          md="6"
                        >
                          <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                        </b-col><b-col
                          cols="6"
                          md="6"
                        >
                          <label
                            v-if="ATTR.subtype === 'datetime'"
                            :for="'attr_'+index"
                          >  {{ ATTR.value > 0 ? new Date(ATTR.value * 1000).toLocaleDateString() + ' ' + new Date(ATTR.value * 1000).toLocaleTimeString() : '-' }}</label>
                          <label
                            v-else
                            :for="'attr_'+index"
                          >{{ ATTR.value }}</label>
                        </b-col></b-row>
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'button'">
                        <b-button
                          variant="gradient-primary"
                          @click="changeValue('ATTR', index, 1)"
                        >
                          {{ ATTR.desc[$i18n.locale] }}
                        </b-button>
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'checkbox'">
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>

                        <b-form-checkbox
                          :id="'attr_'+index"
                          v-model="attrOptionsATTR[index].value"
                          class="custom-control-success"
                          switch
                          @change="changeValue('ATTR', index)"
                          @click="changeValue('ATTR', index)"
                        />

                        <!--                        <b-form-checkbox-->
                        <!--                          :id="'attr_'+index"-->
                        <!--                          v-model="attrOptionsATTR[index].value"-->
                        <!--                          class="custom-control-success"-->
                        <!--                          @change="changeValue('ATTR', index)"-->
                        <!--                          @click="changeValue('ATTR', index)"-->
                        <!--                        />-->
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'input'">
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                        <b-input-group
                          size="sm"
                        >
                          <!--b-input-group-prepend is-text>
                        {{ ATTR.name }}
                      </b-input-group-prepend-->
                          <b-form-input
                            :id="'attr_'+index"
                            v-model="attrOptionsATTR[index].value"
                            debounce="300"
                            @update="attrOptionsATTR[index].canSend = true"
                          />
                          <b-input-group-append>
                            <b-button
                              :variant="attrOptionsATTR[index].canSend ? 'outline-success' : 'outline-secondary'"
                              :disabled="!attrOptionsATTR[index].canSend"
                              @click="changeValue('ATTR', index)"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                class="cursor-pointer"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                    </b-col>

                  </template>

                </b-row>
              </b-card>
            </b-col>
          </b-row>

        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="DollarSignIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('machines.calculation') }}</span>
        </template>
        <b-card>
          <!-- Media -->
          <b-media class="mb-2">
            <h4 class="mb-1">
              {{ machineData.name }}
            </h4>
          </b-media>

          <!-- PERMISSION TABLE -->

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-card
                _no-body
                _class="border mt-1"
              >
                <!--b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="LockIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Price</span>
                  </b-card-title>
                </b-card-header-->

                <b-row _class="px-2">
                  <!-- Field: Email -->
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-form-group
                      :label="$t('machines.planogram')"
                      label-for="machine-planogram"
                    >
                      <v-select
                        v-model="machineData.planogramId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="planogramOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="machine-planogram"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row class="pb-2">
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-button
                      variant="primary"
                      class="btn btn-block mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type_="submit"
                      @click="onSubmit"
                    >
                      {{ $t('machines.save_planogram') }}
                    </b-button>
                  </b-col>
                </b-row>

                <b-row
                  v-for="(PLU, index) in attrOptionsPLU"
                  :key="PLU.name"
                  _class="px-2"
                >
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <!--p>{{ index }} :: {{ PLU.name }}</p-->
                    <b-form-group>
                      <label :for="'plu_'+index">{{ PLU.label }}</label>
                      <b-input-group
                        size="sm"
                      >
                        <b-input-group-prepend is-text>
                          {{ PLU.name }}
                        </b-input-group-prepend>
                        <b-form-input
                          :id="'plu_'+index"
                          v-model="attrOptionsPLU[index].value"
                          debounce="300"
                          type="number"
                          @update="attrOptionsPLU[index].canSend = true; canSendPLU = true;"
                        />
                        <b-input-group-append>
                          <b-button
                            :variant="attrOptionsPLU[index].canSend ? 'outline-success' : 'outline-secondary'"
                            :disabled="!attrOptionsPLU[index].canSend"
                            @click="changeValue('PLU', index)"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="cursor-pointer"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>

                  </b-col>
                </b-row>

                <b-row class="pt-1">
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-button
                      v-if="attrOptionsPLU.length"
                      :disabled="!canSendPLU"
                      variant="gradient-success"
                      class="btn btn-block"
                      @click="changeValue('PLU', -1)"
                    >
                      {{ $t('machines.update_all_price') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="CloudIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('machines.network') }}</span>
        </template>
        <b-card>
          <!-- Media -->
          <b-media class="mb-2">
            <h4 class="mb-1">
              {{ machineData.name }}
            </h4>
          </b-media>

          <b-row>

            <b-col
              cols="12"
              md="3"
            >
              <b-card
                _no-body
                _class="border mt-1"
              >
                <!--b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="LockIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Settings</span>
                  </b-card-title>
                </b-card-header-->
                <b-row>

                  <template v-for="(ATTR, index) in attrOptionsATTR">
                    <b-col
                      v-if="ATTR.group === 'Network'"
                      :key="ATTR.name"
                      cols="12"
                      md="12"
                    >
                      <b-form-group v-if="ATTR.owner === 'client'">
                        <b-row><b-col
                          cols="6"
                          md="6"
                        >
                          <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                        </b-col><b-col
                          cols="6"
                          md="6"
                        >
                          <label :for="'attr_'+index">{{ ATTR.value }}</label>
                        </b-col></b-row>
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'button'">
                        <b-button
                          variant="gradient-primary"
                          @click="changeValue('ATTR', index, 1)"
                        >
                          {{ ATTR.desc[$i18n.locale] }}
                        </b-button>
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'checkbox'">
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>

                        <b-form-checkbox
                          :id="'attr_'+index"
                          v-model="attrOptionsATTR[index].value"
                          class="custom-control-success"
                          switch
                          @change="changeValue('ATTR', index)"
                          @click="changeValue('ATTR', index)"
                        />

                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'input'">
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                        <b-input-group
                          size="sm"
                        >
                          <!--b-input-group-prepend is-text>
                        {{ ATTR.name }}
                      </b-input-group-prepend-->
                          <b-form-input
                            :id="'attr_'+index"
                            v-model="attrOptionsATTR[index].value"
                            debounce="300"
                            @update="attrOptionsATTR[index].canSend = true"
                          />
                          <b-input-group-append>
                            <b-button
                              :variant="attrOptionsATTR[index].canSend ? 'outline-success' : 'outline-secondary'"
                              :disabled="!attrOptionsATTR[index].canSend"
                              @click="changeValue('ATTR', index)"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                class="cursor-pointer"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="SettingsIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('machines.settings') }}</span>
        </template>
        <b-card>
          <!-- Media -->
          <b-media class="mb-2">
            <h4 class="mb-1">
              {{ machineData.name }}
            </h4>
          </b-media>

          <b-row>

            <b-col
              cols="12"
              md="3"
            >
              <b-card
                _no-body
                _class="border mt-1"
              >
                <!--b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="LockIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Settings</span>
                  </b-card-title>
                </b-card-header-->
                <b-row>

                  <template v-for="(ATTR, index) in attrOptionsATTR">
                    <b-col
                      v-if="ATTR.group === 'Settings'"
                      :key="ATTR.name"
                      cols="12"
                      md="12"
                    >

                      <b-form-group v-if="ATTR.owner === 'client'">
                        <b-row><b-col
                          cols="6"
                          md="6"
                        >
                          <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                        </b-col><b-col
                          cols="6"
                          md="6"
                        >
                          <label :for="'attr_'+index">{{ ATTR.value }}</label>
                        </b-col></b-row>
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'button'">
                        <b-button
                          variant="gradient-primary"
                          @click="changeValue('ATTR', index, 1)"
                        >
                          {{ ATTR.desc[$i18n.locale] }}
                        </b-button>
                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'checkbox'">
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>

                        <b-form-checkbox
                          :id="'attr_'+index"
                          v-model="attrOptionsATTR[index].value"
                          class="custom-control-success"
                          switch
                          @change="changeValue('ATTR', index)"
                          @click="changeValue('ATTR', index)"
                        />

                      </b-form-group>
                      <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'input'">
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                        <b-input-group
                          size="sm"
                        >
                          <!--b-input-group-prepend is-text>
                        {{ ATTR.name }}
                      </b-input-group-prepend-->
                          <b-form-input
                            :id="'attr_'+index"
                            v-model="attrOptionsATTR[index].value"
                            debounce="300"
                            @update="attrOptionsATTR[index].canSend = true"
                          />
                          <b-input-group-append>
                            <b-button
                              :variant="attrOptionsATTR[index].canSend ? 'outline-success' : 'outline-secondary'"
                              :disabled="!attrOptionsATTR[index].canSend"
                              @click="changeValue('ATTR', index)"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                class="cursor-pointer"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </template>

                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab v-if="$can('show', 'DiscountQR')">
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('machines.qr_codes') }}</span>
        </template>
        <b-card>
          <!-- Media -->
          <b-media>
            <h4>
              {{ machineData.name }}
            </h4>
          </b-media>

          <b-row>
            <b-col>
              <div class="mb-1">
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <div class="d-flex align-items-right justify-content-end">
                      <b-form-input
                        v-model="qrSearch"
                        class="d-inline-block mr-1"
                        :placeholder="$t('common.search')"
                      />
                      <b-button
                        variant="primary"
                        @click="isAddNewMachineTokenSidebarActive = true"
                      >
                        <span class="text-nowrap">{{ $t('machines.add_qr_code') }}</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>

              </div>

              <b-table
                class="position-relative"
                :items="machineData.QRS ? machineData.QRS.filter(item => !qrSearch || item.name.indexOf(qrSearch) > -1) : []"
                responsive
                :fields="qrtableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
              >
                <template #cell(actions)="data">
                  <b-media vertical-align="center">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="customImage(data.item)"
                    >
                      {{ $t('common.print') }}
                    </b-button>

                    <b-button
                      class="ml-1"
                      variant="outline-danger"
                      size="sm"
                      @click="deleteQR(data.item)"
                    >
                      {{ $t('common.delete') }}
                    </b-button>
                  </b-media>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="DollarSignIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('machines.fixation') }}</span>
        </template>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form @submit="onFixSubmit">
                <b-form-group>
                  <b-form-checkbox
                    v-model="fixData.enable"
                    name="enable"
                  >
                    {{ $t('machines.fixation_form.enable') }}
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :label="$t('machines.fixation_form.license')"
                >
                  <b-form-input
                    v-model.trim="fixData.license"
                    name="license"
                    required
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('machines.fixation_form.login')"
                >
                  <b-form-input
                    v-model.trim="fixData.login"
                    name="login"
                    required
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('machines.fixation_form.password')"
                >
                  <b-form-input
                    v-model.trim="fixData.password"
                    name="password"
                    type="password"
                    required
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('machines.fixation_form.pin')"
                >
                  <b-form-input
                    v-model.trim.number="fixData.pin"
                    name="pin"
                    type="password"
                    required
                  />
                </b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                >
                  {{ $t('common.save_changes') }}
                </b-button>
              </b-form>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-button
                  v-if="typeof fixData.id !== 'undefined'"
                  class="mb-2"
                  variant="gradient-primary"
                  :disabled="!fixData.enable || disableShiftBtn"
                  @click="changeShift()"
                >
                  {{ !fixData.open_shift ? $t('machines.fixation_form.openShift') : $t('machines.fixation_form.closeShift') }}
                </b-button>
                <div
                  v-if="fixData.error_shift"
                  class="text-danger font-weight-bold mb-2"
                >
                  {{ $t('machines.fixation_form.errorShift') }}
                </div>
                <div
                  v-if="lastPaymentUrl"
                  class="font-weight-bold mb-2"
                >{{ $t('machines.lastFixationPayment') }} <a
                  :href="lastPaymentUrl"
                  target="_blank"
                >{{ lastPaymentUrl }}</a></div>
                <template v-for="(ATTR, index) in attrOptionsATTR">
                  <b-col
                    v-if="ATTR.group === 'ZReport'"
                    :key="ATTR.name"
                    cols="12"
                    md="12"
                  >
                    <b-form-group v-if="ATTR.owner === 'client'">
                      <b-row><b-col
                        cols="6"
                        md="6"
                      >
                        <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                      </b-col><b-col
                        cols="6"
                        md="6"
                      >
                        <label
                          v-if="ATTR.subtype === 'datetime'"
                          :for="'attr_'+index"
                        >  {{ ATTR.value > 0 ? new Date(ATTR.value * 1000).toLocaleDateString() + ' ' + new Date(ATTR.value * 1000).toLocaleTimeString() : '-' }}</label>
                        <label
                          v-else
                          :for="'attr_'+index"
                        >{{ ATTR.value }}</label>
                      </b-col></b-row>
                    </b-form-group>
                    <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type === 'button'">
                      <b-button
                        variant="gradient-primary"
                        @click="changeValue('ATTR', index, 1)"
                      >
                        {{ ATTR.desc[$i18n.locale] }}
                      </b-button>
                    </b-form-group>
                    <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type === 'checkbox'">
                      <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>

                      <b-form-checkbox
                        :id="'attr_'+index"
                        v-model="attrOptionsATTR[index].value"
                        class="custom-control-success"
                        switch
                        @change="changeValue('ATTR', index)"
                        @click="changeValue('ATTR', index)"
                      />

                      <!--                        <b-form-checkbox-->
                      <!--                          :id="'attr_'+index"-->
                      <!--                          v-model="attrOptionsATTR[index].value"-->
                      <!--                          class="custom-control-success"-->
                      <!--                          @change="changeValue('ATTR', index)"-->
                      <!--                          @click="changeValue('ATTR', index)"-->
                      <!--                        />-->
                    </b-form-group>
                    <b-form-group v-else-if="ATTR.owner !== 'client' && ATTR.type == 'input'">
                      <label :for="'attr_'+index">{{ ATTR.desc[$i18n.locale] }}</label>
                      <b-input-group
                        size="sm"
                      >
                        <!--b-input-group-prepend is-text>
                      {{ ATTR.name }}
                    </b-input-group-prepend-->
                        <b-form-input
                          :id="'attr_'+index"
                          v-model="attrOptionsATTR[index].value"
                          debounce="300"
                          @update="attrOptionsATTR[index].canSend = true"
                        />
                        <b-input-group-append>
                          <b-button
                            :variant="attrOptionsATTR[index].canSend ? 'outline-success' : 'outline-secondary'"
                            :disabled="!attrOptionsATTR[index].canSend"
                            @click="changeValue('ATTR', index)"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              class="cursor-pointer"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>

        </b-card>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  // BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  // BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormCheckbox,
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormCheckbox,
  BTab, BTabs,
  BTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref, onUnmounted, onMounted,
} from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'
import axios from '@/libs/axios'
import MachineEditTokenAddNew from '../machines-list/MachineEditTokenAddNew.vue'
import useMachinesList from '../machines-list/useMachinesList'

export default {
  components: {
    MachineEditTokenAddNew,
    BTable,
    BTab,
    BTabs,
    BButton,
    BMedia,
    // BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    BCard,
    // BCardHeader,
    // BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
  },
  props: {
    machineData: {
      // type: Object,
      required: true,
      validator: p => ['object'].indexOf(typeof p) !== -1 || p === null,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'machineData.planogramId': function () {
      this.loadPLU(this.machineData.planogramId)
    },
  },
  setup(props, { emit }) {
    const {
      resolveMachineObjectText,
      objectOptions,
      companyOptions,
      typeOptions,
      attrOptionsPLU,
      attrOptionsATTR,
      attrOptionsPLUtmp,
      attrOptionsATTRtmp,
      loadATTR,
      loadPLU,
      saveATTR,
      deleteQR,
      qrtableColumns,
    } = useMachinesList(emit)
    const planogramOptions = ref([])
    const isAddNewMachineTokenSidebarActive = ref(false)
    const qrSearch = ref('')
    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    // attrOptionsPLU.value = []
    // attrOptionsATTR.value = []
    // attrOptionsPLUtmp.value = []
    // attrOptionsATTRtmp.value = []
    const canSendPLU = ref(false)
    loadATTR(router.currentRoute.params.id)
    const refetchMachine = () => {
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!! ???????????')
      emit('refetch-machine')
    }
    const funcLloadATTR = () => {
      refetchMachine()
      loadATTR(router.currentRoute.params.id)
    }
    Vue.prototype.$webSocketsConnect({ subscription: 'machine', id: router.currentRoute.params.id }, funcLloadATTR)
    onUnmounted(() => {
      Vue.prototype.$webSocketsDisconnect()
    })
    const changeValue = (type, index, value = null) => {
      // console.log('FROM CHANGE')
      if (type === 'PLU') {
        canSendPLU.value = false
        if (index > 0) {
          attrOptionsPLU.value[index].canSend = false
          // console.log(type, index, attrOptionsPLU.value[index])
          saveATTR(attrOptionsPLU.value[index], props.machineData, type, index)
        } else {
          for (let ind = 0; ind < attrOptionsPLU.value.length; ind += 1) attrOptionsPLU.value[ind].canSend = false
          // console.log(type, index, attrOptionsPLU.value)
          saveATTR(attrOptionsPLU.value, props.machineData, type, index)
        }
      } else if (type === 'ATTR') {
        // console.log(type, index, attrOptionsATTR.value[index])
        if (value) attrOptionsATTR.value[index].value = value
        // if (attrOptionsATTR.value[index].type === 'checkbox') {
        //   if (attrOptionsATTR.value[index].value) attrOptionsATTR.value[index].value = 1
        //  else attrOptionsATTR.value[index].value = 0
        // }
        attrOptionsATTR.value[index].canSend = false
        saveATTR(attrOptionsATTR.value[index], props.machineData)
      }
    }
    /*
    watch(attrOptionsPLU, (newV, oldV) => {
      console.log(Object.keys(oldV).length, Object.keys(newV).length)
      if (Object.keys(oldV).length !== Object.keys(newV).length) return
      Object.keys(newV).forEach(val => {
        console.log(val)
        if (newV[val].value !== oldV[val].value) {
          console.log('!!!', val)
          attrOptionsPLU[val].canSend = true
        }
      })
      console.log(' PLU OLD', oldV)
      console.log(' PLU NEW', newV)
    }, { deep: true })
    watch(attrOptionsATTR, (newV, oldV) => {
      console.log(Object.keys(oldV).length, Object.keys(newV).length)
      if (Object.keys(oldV).length !== Object.keys(newV).length) return
      console.log(' ATTR OLD', oldV)
      console.log(' ATTR NEW', newV)
    }, { deep: true })
    */
    const permissionsData = ref([
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Machine',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ])
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.machineData.avatar = base64
    })
    const ok = {
      mac: {
        success: null,
        error: '',
      },
    }
    const resOk = ref({})
    resOk.value = ok
    const onSubmit = () => {
      // console.log(props.machineData)
      resOk.value = ok
      store.dispatch('app-machine/addMachine', props.machineData)
        .then(() => {
          router.push({ name: 'apps-machines-list' })
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }
    const QR64 = ref(null)
    const toast = useToast()
    const customImage = item => {
      // console.log(i18n.t('common.for'))
      store.dispatch('app-machine/fetchQR64', item)
        .then(response => {
          QR64.value = response.data
          Vue.swal({
            title: `QR ${i18n.t('common.for')} ${item.name}(#${item.id})`,
            // eslint-disable-next-line global-require
            imageUrl: QR64.value,
            imageWidth: 350,
            imageHeight: 350,
            imageAlt: 'Custom image',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          // console.log(error)
          if (error.status === 400 && error.data.error.amount) {
            // console.log(error.data.error.amount[0])
            toast({
              component: ToastificationContent,
              props: {
                title: error.data.error.amount[0],
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          QR64.value = undefined
        })
    }
    const fixData = ref({
      license: '', password: '', pin: '', login: '', enable: false,
    })
    const lastPaymentUrl = ref('')
    const getFixationData = async () => {
      try {
        const { data: { fixationData } } = await axios.get(`/apps/machine/fixation/${router.currentRoute.params.id}`)
        if (fixationData) {
          fixData.value = {
            ...fixationData,
            enable: !!fixationData.enable,
            open_shift: !!fixationData.open_shift,
            error_shift: !!fixationData.error_shift,
          }
          const { data: { url = '' } } = await axios.get(`/apps/machine/fixation/last-payment/${fixationData.unique_code}`)
          lastPaymentUrl.value = url.length ? `${process.env.VUE_APP_BASE}${url}` : ''
        } else {
          fixationData.value = {
            license: '', password: '', pin: '', login: '', enable: false,
          }
          lastPaymentUrl.value = ''
        }
      } catch (e) {
        console.error(e)
      }
    }
    const getPlanogramOptions = async () => {
      try {
        const { data: { data = [] } } = await axios.get(`/apps/planogram/filterdata/${router.currentRoute.params.id}`)
        planogramOptions.value = data
      } catch (e) {
        console.error(e)
      }
    }
    onMounted(async () => {
      await getFixationData()
      await getPlanogramOptions()
    })
    const disableShiftBtn = ref(false)
    const onFixSubmit = async event => {
      try {
        event.preventDefault()
        const formData = new FormData(event.target)
        await axios.post(`/apps/machine/fixation/${router.currentRoute.params.id}`, {
          license: formData.get('license'),
          login: formData.get('login'),
          password: formData.get('password'),
          pin: formData.get('pin'),
          enable: formData.get('enable'),
        })
        await getFixationData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Form Submitted',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        console.error(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error in save fixation data for machine',
            message: 'Try to check data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }
    const changeShift = async () => {
      disableShiftBtn.value = true
      try {
        // eslint-disable-next-line no-shadow
        const { data: { ok } } = await axios.put(`/apps/machine/fixation/shift/${router.currentRoute.params.id}`)
        await getFixationData()
        toast({
          component: ToastificationContent,
          props: {
            title: ok === 'The shift has successfully opened' ? 'The shift was open' : 'The shift was closed',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        console.error(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error in open/close the shift for machine',
            message: 'Try to check data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        disableShiftBtn.value = false
      }
    }
    // const loadATTR2 = id => {
    //   loadATTR(id)
    // }
    return {
      resolveMachineObjectText,
      avatarText,
      objectOptions,
      companyOptions,
      statusOptions,
      permissionsData,
      typeOptions,
      planogramOptions,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      onSubmit,
      resOk,
      attrOptionsPLU,
      attrOptionsATTR,
      attrOptionsPLUtmp,
      attrOptionsATTRtmp,
      changeValue,
      loadATTR,
      loadPLU,
      canSendPLU,
      qrtableColumns,
      isAddNewMachineTokenSidebarActive,
      refetchMachine,
      customImage,
      deleteQR,
      qrSearch,
      fixData,
      onFixSubmit,
      changeShift,
      disableShiftBtn,
      lastPaymentUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
