<template>
  <component :is="machineData === undefined || true ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="machineData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching machine data
      </h4>
      <div class="alert-body">
        No machine found with this machine id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-machines-list'}"
        >
          Machine List
        </b-link>
        for other machines.
      </div>
    </b-alert>

    <machine-edit-tab-account
      :machine-data="machineData"
      class="mt-2 pt-75"
      @refetch-machine="refetchMachine"
    />

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
// import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import machineStoreModule from '../machineStoreModule'
import MachineEditTabAccount from './MachineEditTabAccount.vue'
// import MachineEditTabInformation from './MachineEditTabInformation.vue'
// import MachineEditTabSocial from './MachineEditTabSocial.vue'
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    MachineEditTabAccount,
    // MachineEditTabInformation,
    // MachineEditTabSocial,
  },
  setup() {
    const machineData = ref(null /* { QRS: [] } */)
    const getData = () => {
      store.dispatch('app-machine/fetchMachine', { id: router.currentRoute.params.id })
        .then(response => { machineData.value = response.data })
        .catch(error => {
          if (error.response.status === 404) {
            machineData.value = null /* { QRS: [] } */
          }
        })
    }

    const USER_APP_STORE_MODULE_NAME = 'app-machine'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, machineStoreModule)
      // console.log('************************ WILL CONNECT')
      // Vue.prototype.$webSocketsConnect({ subscription: 'machine', id: router.currentRoute.params.id }, getData)
    }

    // console.log(Vue.prototype)
    // console.log(Vue.webSocketsService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      // Vue.prototype.$webSocketsDisconnect()
    })

    getData()

    const refetchMachine = () => {
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!!')
      getData()
    }

    return {
      machineData,
      refetchMachine,
    }
  },
}
</script>

<style>

</style>
